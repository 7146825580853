import React from 'react';
import PrivacyPolicy from '../containers/Legal/PrivacyPolicy';
import Layout from '../containers/Layout';

const PrivacyPolicyPage: React.FC = () => (
  <Layout title="Solvana | Privacy Policy">
    <PrivacyPolicy />
  </Layout>
);

export default PrivacyPolicyPage;
